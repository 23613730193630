import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../layouts';
import { TitleAndMetaTags } from '../components/TitleAndMetaTags/TitleAndMetaTags';
import Icon from '../components/Icon/Icon';

export default function Template({ data }) {
	const post = data.markdownRemark;

	return (
		<Layout>
			<div>
				<TitleAndMetaTags
					title={post.frontmatter.title}
					pathname={post.frontmatter.path}
				/>

				<div
					className="post-hero cover bg-center"
					style={{
						backgroundImage: `url(${post.frontmatter.featuredImage.publicURL})`
					}}
				/>

				<div className="ph3-ns pv4">
					<div className="cf ph3 ph2-ns tj w-100 w-60-ns center">
						<h1>{post.frontmatter.title}</h1>
						<div dangerouslySetInnerHTML={{ __html: post.html }} />

						<button className="back-link" onClick={() => window.history.back()}>
							<Icon name="arrow-left" /> Torna indietro
						</button>
					</div>
				</div>
			</div>
		</Layout>
	);
}

export const pageQuery = graphql`
	query BlogPostByPath($path: String!) {
		markdownRemark(frontmatter: { path: { eq: $path } }) {
			html
			frontmatter {
				path
				title
				author
				date
				featuredImage {
					publicURL
					childImageSharp {
						sizes(maxWidth: 1080) {
							...GatsbyImageSharpSizes
						}
					}
				}
			}
		}
	}
`;
